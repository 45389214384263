$rem-value: 8px;

$header-breakpoint: md;
$header-height-mobile: 75px;
$header-height: 75px;

@import "grid";
@import "transitions";
@import "colors";
@import "typography";
