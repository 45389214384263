.l-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;


  &__main {
    flex: 1 1 auto;
    padding-top: $header-height-mobile;


    @include media-breakpoint-up($header-breakpoint){
      padding-top: $header-height;
    }

   
  }
}
