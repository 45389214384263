.a-button {
    background-color: $pink;
    text-transform: uppercase;
    color: $white;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: fit-content;
    border: none;

    &--media {}

    &--courses {}


    &:hover {
        background-color: $pink;
        color: $white;
    }
}
