.a-language-switcher {
    position: fixed;
    right: 3rem;
    z-index: 10000;
    top: 3rem;
    list-style: none;
    display: flex;
    column-gap: 1rem;

    @include media-breakpoint-up($header-breakpoint){
        //top: 3rem;
        right: 5rem;
    }
}
