.m-courses {
    z-index: 10;
    position: relative;
    background-color: $grey-100;

    &__container {

        @include media-breakpoint-up(md) {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__item {
        padding: 3%;

        @include media-breakpoint-up(sm) {
            flex: 1 0 50%;
        }

        @include media-breakpoint-up(lg) {
            flex: 1 0 25%;
            width: 25%;
        }

        &-header {
            background-color: $darker-blue;
            border-top-left-radius: 1rem;
            padding: 2rem;
            min-height: 104px;
        }

        &-content {
            background-color: $white;
            margin-top: 1rem;
            padding: 1rem;
            border-bottom-right-radius: 1rem;
        }

        &-paragraph {
            margin-top: 1.25rem;
            min-height: 240px;
        }
    }
}
