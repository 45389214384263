@use "sass:math";

.o-gallery {
  &__mobile-slider {
    position: relative;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  .mobile-slider {

    &__slide {
      position: relative;
    }

    .slide {
      &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: zoom-in;
      }
    }

    &__button {
      z-index: 2;
      position: absolute;
      top: 50%;
      width: 10rem;
      height: 10rem;
      transition: opacity $transition-base;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        background-color: $white;
        transform: translate(-50%, -50%);
        transition: background-color $transition-base;
      }

      &:hover {
        &:before {
          background-color: $primary;
        }
      }

      &-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &--prev {
        left: 0;
        transform: translate(-50%, -50%);

        .mobile-slider__button-icon {
          transform: translate(-50%, -50%) rotate(90deg);
        }
      }

      &--next {
        right: 0;
        transform: translate(50%, -50%);
      }

      &.is-hidden {
        pointer-events: none;
        opacity: 0;
      }
    }
  }

  &__desktop {
    display: none;

    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-left: math.div(-$grid-gutter-width-desktop, 2);
      margin-right: math.div(-$grid-gutter-width-desktop, 2);
      margin-bottom: -$grid-gutter-width-desktop;
    }

    &-item {
      position: relative;
      width: calc(33.333334% - #{$grid-gutter-width-desktop});
      margin: 0 math.div($grid-gutter-width-desktop, 2) $grid-gutter-width-desktop;
      cursor: zoom-in;

      &-img {
      }
    }
  }
}