.a-image {
  position: relative;
  padding-top: calc(var(--height) / var(--width) * 100%);
  height: 0;
  display: block;
  width: 100%;

  &__img {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: optimizeQuality;
  }
}
