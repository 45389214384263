$white: #fff;
$black: #131313;

$navyblue: #283986;
$orange: #f2af00;

$primary: $navyblue;
$secondary: $orange;

$red-error: #ce0338;
$pink: #9e0041;

$pink-font: #b7295a;

$grey-100: #e2ebf7;
$grey-200: #EAEEEE;
$grey-300: #DDDDDD;
$grey-400: #707070;

$brown-grey: #d2d2d2;

$green-grey: #3d737c;
$blue-grey: #1f7db1;
$blue-grey: #1f7db1;

$light-blue: #e8eff9;
$bg-blue: #2a3977;
$bold-blue: #1060df;

$darker-blue: #0076ce;
$blue: #207db2;
$blue-green: #50898b;
$green: #84955e;
$green-yellow: #a89e3e;
$yellow: #dfa100;

$body-color: $black;
$body-bg: $light-blue;

$highContrastAccent: $orange;
