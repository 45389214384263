.o-jobs {
    &__wrapper {
        margin-top: 5rem;
    }

    &__list {
        list-style: none;

        @include media-breakpoint-up(lg) {

            display: flex;
            column-gap: 3rem;

        }

        &-item {
            display: flex;
            align-items: baseline;
            padding-bottom: 1rem;

            @include media-breakpoint-up(lg) {

                width: 50%;
            }
        }

        &-index {
            border-radius: 50%;
            width: 36px;
            height: 36px;
            padding: 8px;
            border: 2px solid $bold-blue;
            color: $bold-blue;
            line-height: 1;
            text-align: center;
        }

        &-title {
            padding-left: 1rem;
        }
    }

}
