.m-contact {
    &__icon {
        max-width: 120px;
        width: 100%;
    }

    &__wrapper {
        padding-top: 5rem;
        padding-bottom: 5rem;

        @include media-breakpoint-up(md) {
            display: flex;
            column-gap: 5rem;

        }

    }

    &__info {
        padding-top: 3rem;

        @include media-breakpoint-up(md) {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            padding-top: 0;
        }


        &-header {
            padding-bottom: 2rem;
        }

        &-name {
            font-weight: 700;
        }
    }
}
