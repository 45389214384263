@import "functions";

@import "mixins/breakpoints";
@import "mixins/grid";
@import "mixins/grid-framework";

@import "variables/variables";

@import "reboot";

@import "grid";
@import "typography";

@import "node_modules/swiper/swiper";
@import "node_modules/swiper/modules/effect-creative/effect-creative";

@import "../../../includes/views/components/components";
@import "../../../includes/views/layouts/layouts";


#wpadminbar {
  display: none;

  @include media-admin-bar() {
    display: block;
  }
}

#wp-admin-bar-duplicate-post {
  display: none;
}
