.l-header {
  background-color: $white;
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  position: fixed;
  height: 75px;

  body.admin-bar & {
    @media (min-width: 783px) {
      margin-top: 32px;
    }
  }

  @include media-breakpoint-up($header-breakpoint) {
    flex: nowrap;
  }

  &__toggler {
    float: right;
    padding: 20px 60px;
    margin-left: auto;
    display: block;
    padding-right: 12rem;

    @include media-breakpoint-up($header-breakpoint) {
      display: none;
    }

    &-icon {
      display: block;
    }

    &-image {
      width: 20px;
    }
  }
}
