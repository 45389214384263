//
// Headings
//

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
}

h1, .h1 {
  font-size: $h1-font-size-mobile;
  @include media-breakpoint-up($headings-size-breakpoint) {
    font-size: $h1-font-size
  }
}

h2, .h2 {
  font-size: $h2-font-size-mobile;
  @include media-breakpoint-up($headings-size-breakpoint) {
    font-size: $h2-font-size;
  }
}

h3, .h3 {
  font-size: $h3-font-size-mobile;
  @include media-breakpoint-up($headings-size-breakpoint) {
    font-size: $h3-font-size;
  }
}

h4, .h4 {
  font-size: $h4-font-size;
  @include media-breakpoint-up($headings-size-breakpoint) {
    font-size: $h4-font-size;
  }
}

h5, .h5 {
  font-size: $h5-font-size;
  @include media-breakpoint-up($headings-size-breakpoint) {
    font-size: $h4-font-size;
  }
}

h6, .h6 {
  font-size: $h6-font-size;
  @include media-breakpoint-up($headings-size-breakpoint) {
    font-size: $h6-font-size-mobile;
  }
}

//
// Horizontal rules
//

hr {
  margin-top: .5rem;
  margin-bottom: .5rem;
  border: 0;
  border-top: 1px solid $black;
}


//
// Emphasis
//

small,
.small {
  font-size: $font-size-sm;
  font-weight: $font-weight-normal;
}
