.a-logo {
  display: flex;
  align-items: center;

  &__img {
    width: 160px;
    display: block;
    height: auto;

    svg {
      width: 100%;
    }
  }
}
