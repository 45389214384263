.m-hero {
    height: fit-content;
    max-height: 300px;

    &__bg {
        z-index: -2;
        position: absolute;
        top: $header-height-mobile;
        right: 0;
        bottom: 0;
        left: 0;
        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;
        max-height: 300px;
        height: 40vh;
        overflow: hidden;

        @include media-breakpoint-up($header-breakpoint) {
            top: $header-height;
        }

        &-image {
            opacity: 0.2;
            height: 100%;
            z-index: -10;

            @include media-breakpoint-up(lg) {
                //height: unset;
            }
        }
    }

    &__overlay {
        display: block;
        z-index: -4;
        pointer-events: none;
        position: absolute;
        top: $header-height-mobile;
        right: 0;
        left: 0;
        max-height: 300px;
        height: 40vh;
        background-color: $navyblue;

        @include media-breakpoint-up($header-breakpoint) {
            top: $header-height;
        }
    }

    &__content {
        position: relative;
        z-index: 4;
        padding-top: 5vw;
        padding-bottom: 5vw;
        height: 40vh;
        max-height: 300px;

        &-wrapper {
            max-width: 60%;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        }

        &-title {
            color: $white;
            text-transform: uppercase;
        }

        &-description {
            color: $white;
        }

        &-container {
            z-index: 5;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
