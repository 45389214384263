.o-team {

    &__wrapper {

        @include media-breakpoint-up(md) {
            display: flex;
            flex-wrap: wrap;
        }

    }

    &__member {
        margin-top: 3rem;
        margin-bottom: 3rem;

        @include media-breakpoint-up(md) {
            width: 50%;
        }
    }


}
