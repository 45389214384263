.o-services {
    margin-top: -10rem;

    &__header {
        z-index: 100;
        position: relative;
    }

    &__content {
        &-description {
            margin: 1rem;
        }
    }

    &__items {
        display: grid;
        grid-auto-columns: 1fr;
        grid-gap: 3rem;

        @include media-breakpoint-up(sm) {
            grid-template-columns: 1fr 1fr;
        }

        @include media-breakpoint-up(lg) {
            grid-template-columns: 1fr 1fr 1fr;
        }

        @include media-breakpoint-up(xl) {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        }

        &-block {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            width: 100%;
            border-top-left-radius: 2rem;
            border-bottom-right-radius: 2rem;


            @include media-breakpoint-up(xs) {
                flex-direction: row;
            }

            @include media-breakpoint-up(lg) {
                flex-direction: column;
                padding-bottom: 100%;
                height: 0;
            }

            .block {
                @include media-breakpoint-up(xs) {
                    padding-bottom: 50%;
                }

                @include media-breakpoint-up(lg) {
                    padding-bottom: 90%;
                    width: 90%;
                    margin-bottom: 2rem;
                }

                &__inner {
                    padding-top: 2rem;
                    padding-bottom: 1rem;
                    padding-left: 1rem;
                    padding-right: 1rem;

                    @include media-breakpoint-up(lg) {
                        padding-top: 10rem;
                    }

                    @include media-breakpoint-up(xl) {
                        padding-top: 2rem;
                    }
                }

                &__icon {
                    max-width: 60px;
                    height: auto;
                }

                &__description {
                    color: $white;
                    padding-top: 2rem;
                }
            }

            &--trainings {
                background-color: $darker-blue;
            }

            &--implementations {
                background-color: $blue;
            }

            &--managment {
                background-color: $blue-green;
                z-index: 10;
            }

            &--atacks {
                background-color: $green;
            }

            &--assessments {
                background-color: $green-yellow;
            }

            &--outsourcing {
                background-color: $yellow;
            }

        }
    }

    &__list {
        list-style: none;
        padding-left: 0;
        margin: 1rem;

        &-item {

            margin-top: 6rem;

            &:nth-child(8){
                & .item__packages-list{
                    flex-wrap:nowrap;
                }
            }

            .item {
                &__title {
                    color: $bold-blue;
                    position: relative;
                    padding-left: 4rem;

                    &-big {
                        margin-bottom: 0 !important;
                    }

                    &-small {}

                    &:before {
                        content: "";
                        width: 2rem;
                        height: 100%;
                        background: #283986;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }

                &__paragraphs {

                    @include media-breakpoint-up(lg) {
                        display: flex;
                        column-gap: 2rem;
                    }

                    &-paragraph {
                        padding-bottom: 2rem;

                        @include media-breakpoint-up(lg) {
                            width: 50%;
                        }
                    }
                }

                &__packages {
                    &-list {
                        list-style: none;
                        padding-left: 0;

                        @include media-breakpoint-up(lg) {
                            display: flex;
                            flex-wrap: wrap;
                        }
                    }

                    &-item {
                        display: flex;
                        align-items: center;
                        padding-bottom: 1rem;

                        @include media-breakpoint-up(lg) {
                            width: 33.33%;
                            margin: 0;
                            padding-right: 2rem;
                        }
                    }

                    &-index {
                        border-radius: 50%;
                        width: 36px;
                        height: 36px;
                        padding: 8px;
                        border: 2px solid $bold-blue;
                        color: $bold-blue;
                        line-height: 1;
                        text-align: center;
                        flex-shrink: 0;
                    }

                    &-title {
                        padding-left: 1rem;
                    }
                }

                &__header {
                    color: $bold-blue;
                    padding-top: 2rem;
                    padding-bottom: 2rem;
                }

                &__options {
                    padding-top: 2rem;
                    display: flex;
                    column-gap: 5rem;

                    &-form {
                        .form {
                            &__wrapper {
                                display: flex;
                                column-gap: 2rem;
                            }

                            &__inner {
                                background-color: $navyblue;
                                padding: 1rem;
                                border-top-left-radius: 2rem;
                                border-bottom-right-radius: 2rem;
                                display: flex;
                                min-width: 200px;
                            }

                            &__name {
                                color: $white;
                                padding-left: 1rem;
                                font-weight: 500;
                            }
                        }
                    }
                }

                &__alarms {
                    &-list {
                        list-style: none;
                        padding-left: 0;
                    }

                    &-alarm {
                        display: flex;
                        column-gap: 2rem;
                        background-color: $white;
                        border: 2px solid $red-error;
                        border-radius: 1rem;
                        padding: 1rem;
                        margin-top: 2rem;
                        margin-bottom: 2rem;
                    }

                    &-image {
                        width: 45px;
                        height: 45px;
                    }

                    &-title {
                        color: $pink-font;
                    }
                }

                &__add-info {
                    margin-top: 2rem;
                    margin-bottom: 2rem;
                }

                &__references {
                    &-image {
                        margin-left: auto;
                        margin-right: auto;
                        display: block;
                    }

                    &-label {
                        text-align: center;
                    }

                    .references {
                        &__swiper {}

                        &__wrapper {
                            position: relative;
                        }

                        &__button {
                            position: absolute;
                            top: 50%;
                            display: none;

                            @include media-breakpoint-up(sm) {
                                display: block;
                            }

                            &--prev {
                                left: -45px;
                            }

                            &--next {
                                right: -45px;
                            }
                        }
                    }
                }

                &__exams {

                    @include media-breakpoint-up(lg) {
                        display: flex;
                        column-gap: 2rem;
                    }

                    &-exam {
                        padding-top: 2rem;
                        padding-bottom: 2rem;

                        @include media-breakpoint-up(lg) {
                            width: 50%;
                        }

                        .exam {
                            &__header {
                                background-color: $darker-blue;
                                color: $white;
                                border-top-left-radius: 1rem;
                                padding: 2rem;
                                min-height: 104px;
                                display: flex;
                                align-items: center;
                                width: 100%;
                                position: relative;
                                overflow: hidden;

                                &-title {
                                    margin: 0;
                                    width: 66.66%;
                                }

                                &-icon {
                                    width: 100%;
                                    max-width: 150px;
                                    z-index: 50;
                                }

                                &-secondary {
                                    width: 33.33%;
                                    background-color: $navyblue;
                                    height: 100%;
                                    position: absolute;
                                    right: 0;
                                    top: 0;
                                    display: flex;
                                    align-items: center;

                                    &:after {
                                        content: "";
                                        display: block;
                                        width: 30%;
                                        transform: rotation(-50deg);
                                        background-color: $navyblue;
                                        position: absolute;
                                        left: -15%;
                                        top: -15%;
                                        height: 120%;
                                        -webkit-transform: rotate(-25deg);
                                        transform: rotate(-25deg);
                                    }
                                }
                            }

                            &__content {
                                background-color: $white;
                                margin-top: 1rem;
                                padding: 2rem;
                                border-bottom-right-radius: 1rem;

                                @include media-breakpoint-up(lg) {
                                    min-height: 420px;
                                    display: flex;
                                    flex-direction: column;
                                    justify-content: space-evenly;
                                }

                                &-header {
                                    margin-bottom: 0;
                                }

                                &-wrapper {
                                    padding-top: 1rem;
                                    padding-bottom: 1rem;
                                }
                            }

                            &__paragraph {
                                margin-top: 1.25rem;
                                min-height: 240px;
                            }
                        }
                    }

                }
            }
        }
    }
}
