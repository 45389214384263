.o-main-nav {
  max-height: 0;
  transition: max-height $transition-base;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  background-color: $white;
  flex-basis: 100%;

  @include media-breakpoint-up($header-breakpoint) {
    overflow: visible;
    margin-left: unset;
    margin-right: unset;
    flex-basis: auto;
    //max-height: 75px;
  }


  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin: 10px;
    column-gap: 3rem;
    padding-left: 0;

    @include media-breakpoint-up($header-breakpoint) {
      padding-left: 8rem;
      justify-content: space-between;
    }
  }

  &__item {
    width: 100%;
    text-align: center;
    position: relative;

    @include media-breakpoint-up($header-breakpoint) {
      width: auto;
    }
  }

  &__link {
    display: block;
    padding: 15px 5px;
    color: $navyblue;
    text-transform: uppercase;
    position: relative;
    font-weight: 500;

    &:hover {
      color: $navyblue;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 90%;
        height: 3px;
        bottom: -14px;
        background: $orange;
        transition: $transition-base;
      }
    }

    &-icon {
      -webkit-mask-image: url(../../../../../assets/private/img/home-icon.svg);
      mask-image: url(../../../../../assets/private/img/home-icon.svg);
      filter: brightness(0) saturate(100%) invert(14%) sepia(72%) saturate(2707%) hue-rotate(223deg) brightness(94%) contrast(86%);
    }
  }

  &__submenu {
    max-height: 0;
    -webkit-transition: max-height 0.3s ease-in-out;
    transition: max-height 0.3s ease-in-out;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    background-color: $light-blue;

    @include media-breakpoint-up($header-breakpoint) {
      position: absolute;
      left: 0;
      width: 20rem;
      margin-top: 13px;
    }

    .submenu {
      &__list {
        list-style: none;
        padding: 0;
      }

      &__item {
        padding-bottom: 1rem;
        padding-top: 1rem;

        @include media-breakpoint-up($header-breakpoint) {
          padding-left: 1rem;
          padding-right: 5rem;
          border-bottom: 1px solid $brown-grey;
        }

        &:hover {
          transition: background-color $transition-base, color $transition-base;
          background-color: $orange;
          color: $white;
        }
      }

      &__link {
        &:hover {
          color: $white;
        }

        @include media-breakpoint-up($header-breakpoint) {
          text-align: left;
          display: inline-block;
          width: 100%;
        }

      }
    }
  }

  &__toggler {
    float: right;
    margin-left: auto;
    display: block;

    position: absolute;
    top: 0px;
    transform: translate(50%, 50%);
    left: 53%;

    @include media-breakpoint-up($header-breakpoint) {
      right: 0;
      margin-left: 10px;
    }

    &-icon {
      display: block;
    }

    &-image {
      width: 7px;

      -webkit-mask-image: url(../../../../../assets/private/img/caret-right-solid.svg);
      mask-image: url(../../../../../assets/private/img/caret-right-solid.svg);
      filter: brightness(0) saturate(100%) invert(14%) sepia(72%) saturate(2707%) hue-rotate(223deg) brightness(94%) contrast(86%);

      &--rotate {
        transform: rotate(90deg);
      }
    }
  }
}
