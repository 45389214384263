.m-text-editor {
    &__content {
        @include media-breakpoint-up(xl) {
            padding: 0 calc(8.33333333%);
        }

        ul {
            list-style: none;
            padding: 0;

            li {
                position: relative;
                padding-left: 3ch;

                &:after {
                    content: '';
                    position: absolute;
                    top: $line-height-base / 2 + 1;
                    left: 0;
                    width: 1.5ch;
                    height: 1px;
                    background-color: $primary;

                    body.is-high-contrast-active {
                        background-color: $white;
                    }
                }
            }
        }

        a {
            font-weight: $font-weight-medium;
            transition: color $transition-base;

            body.is-high-contrast-active {
                color: $highContrastAccent;
            }
        }

        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .l-sidebar &__content {
        @include media-breakpoint-up(xl) {
            padding: 0 calc(8.33333333%) 0 0;
        }
    }
}
