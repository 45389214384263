.o-company {
    z-index: 100;
    position: relative;

    &__columns {
        padding: 6rem;

        &-container {
            display: flex;
            flex-wrap: wrap;

            @include media-breakpoint-up(lg) {
                flex-wrap: nowrap;
            }
        }

        &-column {
            padding: 2rem;
        }
    }

    &__about {
        background-color: $navyblue;
        padding-top: 2rem;

        &-inner {
            @include media-breakpoint-up(md) {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
            }
        }

        &-item {
            padding: 2rem;

            @include media-breakpoint-up(md) {
                flex-shrink: 4;
                flex-grow: 1;
                max-width: 33.33%;
                width: 33.33%
            }

            @include media-breakpoint-up(xl) {
                max-width: 25%;

                &:nth-child(-n+5) {
                    width: 25%;
                }

                &:nth-child(n+5) {
                    & .item{
                        &__wrapper {
                            padding-bottom: 70%;
                        }
                    }
                }
            }

            &:nth-child(n+5) {
                & .item{
                    &__description {
                        padding-top: 2rem;
                    }
                }
            }

            .item {

                &__wrapper {
                    background-color: $blue;
                    border-top-left-radius: 1rem;
                    border-bottom-right-radius: 1rem;
                    padding: 3rem;
                    min-height: 100%;

                    @include media-breakpoint-up(lg) {
                        height: 0;
                        padding-bottom: 100%;
                        position: relative;

                    }
                }

                &__content {
                    @include media-breakpoint-up(lg) {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 80%;
                    }

                }

                &__icon {
                    width: 50px;
                    margin-left: auto;
                    margin-right: auto;
                }

                &__countup {
                    display: flex;
                    justify-content: center;
                    color: $orange;
                    font-size: xx-large;
                    padding-top: 1rem;
                    padding-bottom: 1rem;
                }

                &__description {
                    text-align: center;
                    color: $white;
                    font-size: small;
                }
            }

        }
    }
}
