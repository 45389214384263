.o-footer {
  padding: 2rem 0;
  background: $navyblue;
  z-index: 10;
  color: $white;

  &__container {
    display: flex;
    flex-wrap: wrap;
  }

  &__contact {}

  &__contact-info {}

  &__nav {
    text-align: center;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-up(md) {
      margin-left: unset;
      margin-right: unset;
    }
  }

  .nav {
    &__list {
      list-style: none;
      padding-left: 0;

      @include media-breakpoint-up(md) {
        display: flex;
        width: max-content;
      }
    }

    &__item {

      @include media-breakpoint-up(md) {
        padding-left: 3rem;

        &:before {
          content: "|";
          color: $white;
        }
      }

    }

    &__link {
      color: $white;

      @include media-breakpoint-up(md) {
        margin-left: 2rem;
      }
    }
  }

  &__copyright {
    flex-basis: 100%;

    &-text {
      display: block;
      margin: 0;
      text-align: center;
    }
  }

  &__bottom {
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;

    @include media-breakpoint-up(md) {
      flex-wrap: unset;
    }
  }

  &__socials {
    width: 100%;
    justify-content: center;
    display: flex;
    column-gap: 2rem;
    padding-top: 3rem;
    padding-bottom: 5rem;
  }
}
