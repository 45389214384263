.o-references {
    margin-top: 5rem;
    margin-bottom: 5rem;

    &__list {
        list-style: none;

        @include media-breakpoint-up(lg) {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__item {
        margin-top: 3rem;
        margin-bottom: 3rem;

        @include media-breakpoint-up(lg) {
            width: 50%;
        }

        &.is-hidden {
            display: none;
        }
    }

    &__search {

        @include media-breakpoint-up(md) {
            display: flex;
            align-items: center;
        }

        &-button {
            background-color: $pink;
            text-transform: uppercase;
            color: $white;
            padding: 0.5rem 1rem;
            border-radius: 1rem;
            margin-left: auto;
            margin-right: auto;
            display: block;
            width: fit-content;
            border: none;

            @include media-breakpoint-up(md) {
                width: 15%;
                height: 100%;
                margin-top: 10px;
            }

            &:hover {
                background-color: $pink;
                color: $white;
            }
        }
    }

    &__select {
        width: 100%;
        max-width: 30rem;

        &-wrapper {
            padding-bottom: 1rem;

            @include media-breakpoint-up(md) {
                flex-shrink: 0;
                width: 25%;
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }
    }
}
