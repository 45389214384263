.m-trust {
    background-color: $white;
    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

    }

    &__item {
        padding-top: 2rem;
        padding-bottom: 2rem;
        width: 100%;
    }
}
