@import "photoSwipe/main";
@import "photoSwipe/default-skin/default-skin";

.pswp {
  body.admin-bar & {
    @media (min-width: 783px) {
      top: 32px;
      height: calc(100% - 32px);
    }
  }

  &__button {
    opacity: 1;

    &--close {
    }

    &--arrow--left, &--arrow--right {
    }

    &--arrow--left {
    }
  }

  &__counter {
    top: auto;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    font-size: inherit;
  }
}
