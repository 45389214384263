.m-image-text {
    &__image {
        max-width: 180px;
        width: 100%;

        @include media-breakpoint-up(sm) {
            display: flex;
        }

        &-item {

            @include media-breakpoint-up(sm) {
                width: 100%;
                flex-shrink: 0;
            }

        }
    }

    &__name {
        font-weight: 700;
        color: $bold-blue;
        padding: 1rem;
        padding-left: 3rem;
        position: relative;
        word-spacing: 100vw;
        margin-top: auto;

        &:before {
            content: "";
            width: 2rem;
            height: 100%;
            background: $navyblue;
            position: absolute;
            top: 0;
            left: 0;
        }

        &-wrapper {
            display: flex;
            flex-direction: column;
            padding-top: 1rem;

            @include media-breakpoint-up(sm) {
                padding-left: 2rem;
            }

        }
    }

    &__text {
        padding: 2rem;
    }

    &--references {
        & .m-image-text {
            &__name {
                word-spacing: unset;
                margin-top: 0;
                padding-top: 0
            }

            &__position {
                font-size: smaller;
                font-weight: 400;
            }

            &__image {
                max-width: 100%;

                &-item {
                    max-width: 130px;
                }
            }

            &__text {
                padding-left: 0;
            }

            &__taxonomies {
                display: flex;
                column-gap: 1rem;
                color: $white;
                font-size: small;

                @include media-breakpoint-up(sm) {
                    padding-left: 2rem;
                }

                &-taxonomy {
                    padding: 0.5rem 1rem;
                    border-radius: 1.5rem;
                    margin-top: 1rem;
                    margin-bottom: 1rem;

                    &--sector {
                        background-color: $green-grey;
                    }

                    &--size {
                        background-color: $blue-grey;
                    }

                    &--service {
                        background-color: $yellow;
                    }
                }


            }
        }
    }
}
