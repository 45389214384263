.o-media {

    &__logo {

        margin-top: 2rem;

        @include media-breakpoint-up(lg) {
            display: flex;
            justify-content: space-around;
        }

        &-item {

            margin-left: auto;
            margin-right: auto;
            padding-top: 1rem;
            padding-bottom: 1rem;

            @include media-breakpoint-up(lg) {
                width: 25%;
            }

        }


        &-image {
            margin-left: auto;
            margin-right: auto;
            display: block;
        }
    }

    &__content {
        margin-bottom: 3rem;

        &-title {}

        &-more {
            @include media-breakpoint-up(md) {
                display: flex;
            }

            .more {
                &__video {
                    &-item {
                        width: 100%;
                        height: auto;
                        max-width: 540px;
                        padding: 3rem;
                    }
                }
            }
        }
    }
}
