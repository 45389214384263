.m-video {
    margin-top: 5rem;
    margin-bottom: 5rem;

    &__list {
        list-style: none;
    }

    &__item {
        margin-bottom: 3rem;

        @include media-breakpoint-up(lg) {
            display: flex;
        }

        &-video {
            width: 100%;
            height: auto;
            max-width: 540px;

            &-wrapper {
                flex-shrink: 0;
            }
        }

        &-content {
            width: 100%;
        }

        &-wrapper {
            display: flex;
        }

        &-label {
            width: 33.33%;
            flex-shrink: 0;

            @include media-breakpoint-up(xxl) {
                width: 15%;
            }

            .label {
                &__inner {
                    width: fit-content;
                    float: right;

                    &:after {
                        content: ":";
                    }
                }
            }

        }

        &-description {
            text-transform: uppercase;
            color: $navyblue;
            padding-left: 1.5rem;
            font-weight: 500;
            display: flex;
            flex-wrap: wrap;
        }

        &-term {
            width: fit-content;

            &:not(:first-child) {
                //padding-left: 1rem;
            }


            &:not(:last-child) {
                &:after {
                    content: ", \00a0";
                }
            }
        }

        &-info{
            padding-top:3rem;
            padding-left:6rem;
        }
    }
}
