.o-homepage {
    &__bg {
        z-index: 1;
        position: absolute;
        top: $header-height-mobile;
        right: 0;
        bottom: 0;
        left: 0;
        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;
        max-height: 100%;
        background-color: $bg-blue;
        overflow: hidden;
    }

    &__inner {
        background-color: $bg-blue;
    }

    &__content {
        &-wrapper {
            position: relative;
            z-index: 2;
        }

        &-title {
            color: $orange;
            text-align: center;
        }

        &-description {
            color: $white;
            text-align: center;
        }

        &-container {
            margin-top: 6rem;
        }
    }

    &__technologies {
        display: grid;
        grid-auto-columns: 1fr;
        grid-gap: 1rem;

        @include media-breakpoint-up(lg) {
            grid-template-columns: 1fr 1fr 1fr;
        }

        @include media-breakpoint-up(xl) {
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        }

        &-block {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;

            &:nth-child(2n) {
                & .block__primary {
                    border-bottom-right-radius: 2rem;
                    border-top-left-radius: 0;
                }
            }

            @include media-breakpoint-up(xs) {
                flex-direction: row;
            }

            @include media-breakpoint-up(lg) {
                flex-direction: column;

                &:nth-child(2n) {
                    flex-direction: column-reverse;
                }
            }


            .block {
                &__primary {
                    height: 0;
                    width: 100%;
                    padding-bottom: 80%;
                    margin: 1rem;

                    border-top-left-radius: 2rem;

                    @include media-breakpoint-up(xs) {
                        padding-bottom: 50%;
                    }

                    @include media-breakpoint-up(lg) {
                        padding-bottom: 90%;
                        width: 90%;
                        margin-bottom: 2rem;
                    }

                    &-inner {
                        padding-top: 6rem;
                        padding-bottom: 1rem;
                        padding-left: 1rem;
                        padding-right: 1rem;

                        @include media-breakpoint-up(md) {
                            padding-top: 10rem;
                        }

                        @include media-breakpoint-up(xl) {
                            padding-top: 2rem;
                        }
                    }

                    &-icon {
                        max-width: 60px;
                        height: auto;
                    }

                    &-description {
                        color: $white;
                        padding-top: 2rem;
                    }

                    &--trainings {
                        background-color: $darker-blue;
                    }

                    &--implementations {
                        background-color: $blue;
                    }

                    &--managment {
                        background-color: $blue-green;
                        z-index: 10;
                    }

                    &--atacks {
                        background-color: $green;
                    }

                    &--assessments {
                        background-color: $green-yellow;
                    }

                    &--outsourcing {
                        background-color: $yellow;
                    }
                }

                &__secondary {
                    width: 100%;



                    @include media-breakpoint-up(lg) {
                        height: 0;
                        padding-bottom: 100%;

                        &--trainings {
                            width: 110%;
                        }

                        &--implementations {
                            width: 118%;
                        }

                        &--managment {
                            width: 128%;
                            position: absolute;
                            bottom: 2.5rem;
                        }

                        &--atacks {
                            width: 110%;
                        }

                        &--assessments {
                            width: 128%;
                            position: absolute;
                            bottom: 2.5rem;
                        }

                        &--outsourcing {
                            width: 128%;
                            position: absolute;
                            top: -2rem;
                        }
                    }
                }
            }
        }
    }
}
