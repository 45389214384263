.m-icons {
    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

    }

    &__item {
        padding-top: 2rem;
        padding-bottom: 2rem;
        max-width: 360px;
        width: 100%
    }
}
